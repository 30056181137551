import React from 'react';
import PropTypes from "prop-types";
import { Link } from "gatsby";

const Breadcrumbs = ({ breadcrumbs, pageUrl }) => {
  const separator = ' \\ ';
  return (
    <div className="breadcrumbs wrapper">
      {breadcrumbs && breadcrumbs.length > 0 ?
        breadcrumbs.map((breadcrumb, index) =>
          <div key={index + breadcrumb.url}>
            {breadcrumb.url.replace(/^\/category\//g, '/') !== pageUrl ?
              <>
                <Link key={index + breadcrumb.url} to={breadcrumb.url.replace(/^\/category\//g, '/')}>
                  {breadcrumb.url === '/' ?
                    <span className={"home-crumb"} aria-label={"Home"}/>
                    : <span dangerouslySetInnerHTML={{ __html: breadcrumb.text }} />
                  }
                </Link>
                <span className="sep">{separator}</span>
              </> : <span key="title" dangerouslySetInnerHTML={{ __html: breadcrumb.text }} />
            }
          </div>
        ) : ''}
    </div>
  )
};

export default Breadcrumbs;

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array,
  pageUrl: PropTypes.string,
};