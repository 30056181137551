import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Logo from "../../assets/svg/logo.inline.svg";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const PostTile = ({ post, ...props }) => {
  if (!post) return;
  const imageData = getImage(post.featuredImage?.node?.localFile);
  return (
    <li className={"post-tile"} {...props}>
      <Link to={post.uri}>
        {imageData ?
          <GatsbyImage alt={post.featuredImage.node.altText} image={imageData}/> :
          <div className={"placeholder"}>
            <Logo/>
          </div>
        }
        <h4>{post.title}</h4>
        <div className="post-excerpt" dangerouslySetInnerHTML={{ __html: post.excerpt }}/>
      </Link>
    </li>
  );
};

export default PostTile;

PostTile.propTypes = {
  index: PropTypes.number,
  post: PropTypes.object,
};
