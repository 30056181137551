import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../../components/shared/Layout";
import Seo from "../../components/SEO/SEO";
import Breadcrumbs from "../../components/shared/Breadcrumbs";
import FeaturedMedia from "../../components/shared/FeaturedMedia";
import ContentFromWp from "../../components/shared/ContentFromWP";
import RelatedPosts from "../../components/shared/RelatedPosts";
import Tags from "../../components/shared/Tags";
import "../../assets/styles/pages/post.scss";

const post = ({ data }) => {
  const { nextPage, previousPage, page } = data;
  const {
    title,
    uri,
    content,
    featuredImage,
    excerpt,
    seo,
    contextualRelated,
    tags
  } = page;

  return (
    <Layout>
      <Seo title={seo.title ?? title} description={seo.metaDesc ?? excerpt} socialImage={featuredImage?.node} uri={uri}/>
      <Breadcrumbs breadcrumbs={seo.breadcrumbs} pageTitle={seo.title} pageUrl={uri}/>
      <div className={"wrapper"}>
        <h1 className="entry-title" dangerouslySetInnerHTML={{ __html: title }}/>
        <article>
          {featuredImage && <FeaturedMedia image={featuredImage}/>}

          <ContentFromWp>
            <div dangerouslySetInnerHTML={{ __html: content }}/>
          </ContentFromWp>
        </article>
        {tags?.nodes?.length && <Tags tags={tags.nodes}/>}
        {(previousPage?.uri || nextPage?.uri) &&
        <div className={previousPage?.uri ? "prev-next-wrap" : "prev-next-wrap no-prev"}>
          {previousPage?.uri && <Link className={"button button-prev"} to={previousPage.uri}>&#8592;&nbsp; Previous</Link>}
          {nextPage?.uri && <Link className={"button button-next"} to={nextPage.uri}>Next &nbsp;&#8594;</Link>}
        </div>
        }
        {contextualRelated?.length ?
          <RelatedPosts relatedPosts={contextualRelated} /> : null
        }
      </div>
    </Layout>
  );
};

export const query = graphql`
  query post($id: String!, $nextPage: String, $previousPage: String) {
    page: wpPost(id: { eq: $id }) {
      ...PostContent
    }
    nextPage: wpPost(id: { eq: $nextPage }) {
      title
      uri
    }
    previousPage: wpPost(id: { eq: $previousPage }) {
      title
      uri
    }
  }
`;

export default post;
