import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const FeaturedMedia = ({ image }) => {
  const imageData = getImage(image?.node?.localFile);

  if (!imageData) return null;

  return (
    <div className="featured-media">
      <GatsbyImage alt={image.node.altText} image={imageData}/>
    </div>
  );
};

export default FeaturedMedia;
