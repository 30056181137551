import React from "react";
import PropTypes from "prop-types";
import PostTile from "./PostTile";

const RelatedPosts = ({ relatedPosts }) => {
  return (
    <section className={"related-posts"}>
      <h2>Update articles, news from Gclub</h2>
      <ul className={"related-list post-list"}>
        {relatedPosts.map((item, index) =>
        {
          return <PostTile index={index} post={item} key={"post-tile-" + index}/>
        })
        }
      </ul>
    </section>
  );
};

export default RelatedPosts;

RelatedPosts.propTypes = {
  relatedPosts: PropTypes.array,
};
