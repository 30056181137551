import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const Tags = ({ tags }) => {
  return (
    <section className={"tags"}>
      {tags.map((item) =>
      {
        return <Link className={"tag-link"} to={item.uri} key={item.uri}>{item.name}</Link>
      })
      }
    </section>
  );
};

export default Tags;

Tags.propTypes = {
  tags: PropTypes.array.isRequired,
};
